import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Barcode from 'react-barcode';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { environment } from "./util/baseUrl";


export const ListaCertificados = () => {

    let empty = {
        id: null,
        codigo: '',
        fecha: '',
        activo: '',
        usuario: {
            id: '',
            username: '',
            email: '',
            nombre: '',
            apellido: '',
            docIdentidad: '',
            fecNacimiento: '',
            nroTelefono: '',
            dependencia: '',
            institucion: '',
            cargo: '',
            genero: '',
            estado: '',
        },
        curso: {
            id: '',
            codigo: '',
            titulo: '',
            descripcion: '',
            ponente: '',
            hora: '',
            fecha: '',
            resolucion: '',
            flyer: '',
            activo: ''
        },
    };


    const baseUrl = environment.baseUrl + "certificado/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const { id } = useParams();
    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const peticionGet = async () => {
        await axios.get(baseUrl+`listacurso/${id}`)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete product.id;
        await axios.post(baseUrl, product)
            .then(response => {
                setData(data.concat(response.data));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPut = async () => {
        await axios.put(baseUrl, product)
        .then(() => {
                var dataNueva = data;
                dataNueva.map(u => {
                    if (u.id === product.id) {
                        u.titulo = product.titulo;
                        u.descripcion = product.descripcion;
                    }
                    return u;
                });
                setData(dataNueva);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    useEffect(() => {
        peticionGet()
    }, []);

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }
    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }
    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    }
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (product.titulo.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPut();
            }
            else {
                peticionPost();
                _products.push(_product);
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    }
    
    const peticionDelete = async () => {
        await axios.delete(baseUrl + product.id)
            .then(response => {
                const updatedProducts = data.filter(val => val.id !== product.id);
                setData(updatedProducts);
                setDeleteProductDialog(false);
                setProduct(empty);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Curso eliminado correctamente', life: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el curso', life: 5000 });
            });
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const deleteProduct = () => {
        let _products = data.filter(val => val.id !== product.id);
        peticionDelete();
        setData(_products);
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    /* const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Registrar Categoria" icon="pi pi-user-plus" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    } */

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codigoBodyTemplate = (rowData) => {
        return (
            <>
                <Barcode value={rowData.codigo} height={20} width={1} />
            </>
        );
    }

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.nombre+' '+rowData.usuario.apellido}
            </>
        );
    }

    const dniBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.docIdentidad}
            </>
        );
    }

    const correoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.email}
            </>
        );
    }

    const telefonoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.nroTelefono}
            </>
        );
    }

    const institucionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.institucion}
            </>
        );
    }

    const cargoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.cargo}
            </>
        );
    }

    const generoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.usuario.genero}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Modificar Categoria" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button title="Eliminar Categoria" icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Registro de Certificados</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Certificados"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="codigo" header="Codigo Unico" sortable body={codigoBodyTemplate}></Column>
                        <Column field="usuario.apellido" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="usuario.docIdentidad" header="Doc. Entidad" sortable body={dniBodyTemplate}></Column>
                        <Column field="usuario.email" header="Correo" sortable body={correoBodyTemplate}></Column>
                        <Column field="usuario.nroTelefono" header="Telefono" sortable body={telefonoBodyTemplate}></Column>
                        <Column field="usuario.institucion" header="Institucion" sortable body={institucionBodyTemplate}></Column>
                        <Column field="usuario.cargo" header="Cargo" sortable body={cargoBodyTemplate}></Column>
                        <Column field="usuario.genero" header="Género" sortable body={generoBodyTemplate}></Column>
                        {/* <Column body={actionBodyTemplate}></Column> */}
                    </DataTable>
                    <Dialog visible={EntidadNewDialog} style={{ width: '500px' }} header="Datos" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputText placeholder="Título" id="titulo" name="titulo" value={product.titulo} onChange={(e) => onInputChange(e, 'titulo')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.titulo })} />
                            </div>
                            {submitted && !product.titulo && <small className="p-invalid">el titulo es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="documento">Descripcion de la categoría</label>
                            <InputTextarea placeholder="Descripcion de la categoría" id="descripcion" value={product.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                            {submitted && !product.descripcion && <small className="p-invalid">Agregar la descripcion de la categoría.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Esta seguro de deshabilitar la el certificado <b>{product.codigo}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default ListaCertificados;
