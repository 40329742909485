import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { environment } from "./util/baseUrl";
import { AsistenteBicentenarioEntity } from './Entity/AsistenteBicentenarioEntity';
import { AsistenciaEntity } from './Entity/AsistenciaEntity';

export const AsisBicentenario = () => {

    let empty = AsistenteBicentenarioEntity;
    let asist = AsistenciaEntity;

    const baseUrl = environment.baseUrl + "bicentenario/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [bloquearBoton, setBloquearBoton] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [asistenciaData, setAsistenciaData] = useState(null);
    const [product, setProduct] = useState(empty);
    const [reniec, setReniec] = useState(null);
    const [asistencia, setAsistencia] = useState(asist);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionGetAsistencia = async (product) => {
        await axios.get(environment.baseUrl + 'regbicentenario/bicentenario/'+product.id)
            .then(response => {
                setAsistenciaData(response.data);
                setDisplayDialog(true);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete asistencia.id;
        console.log(asistencia);
        await axios.post(environment.baseUrl + 'regbicentenario/', asistencia)
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Registro Correcto', detail: 'Se registro la asistencia a ', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPostAsistente = async () => {
        delete product.id;
        await axios.post(baseUrl, product)
            .then(response => {
                setData(data.concat(response.data));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'El Asistente ya fue registrado', life: 5000 });
            })
    }

    const peticionPutAsistente = async () => {
        try {
            await axios.put(baseUrl, product)
                .then(() => {
                    var dataNueva = data.map(u => {
                        if (u.id === product.id) {
                            u.nombre = product.nombre;
                            u.apellido = product.apellido;
                        }
                        return u;
                    });
                    setData(dataNueva);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
                })
                .catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
                });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        peticionGet();
    }, []);

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', options);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const saveProduct = (product) => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            asistencia.fecRegistro = formatDate(new Date());
            asistencia.asistenteBicentenario.id = product.id; // Accede al id de la fila seleccionada
            peticionPost();
            setEntidadNewDialog(false);
            setProduct(empty);
        }
    }

    const saveAsistente = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPutAsistente();
            }
            else {
                peticionPostAsistente();
                _products.push(_product);
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const AgregarRegistro = (product) => {
        setProduct({ ...product });
        saveProduct(product);
    }

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    }

    const VerRegistro = (product) => {
        setProduct({ ...product });
        peticionGetAsistencia(product);
    }

    const bloquearBotonSeg = () => {
        setBloquearBoton(true); // Deshabilitar el botón
        setTimeout(() => {
            setBloquearBoton(false); // Habilitar el botón después de 15 segundos
        }, 3500);
    };

    const BuscarDNI = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            peticionRENIEC();
            //setProduct(empty);
        }
    }

    const peticionRENIEC = async () => {
        /*await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQG91dGxvb2suY29tIn0.hfkwOQKGq46Czu7RvqI1iSwgN8UGK02UEF4S9ZqXaCQ")*/
        await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InNzYW5kb3ZhbGFAcGouZ29iLnBlIn0.tPut2NowvCp7EleTnEe03lyzMUinI6XRdzYiehJ04Xo")
        /*await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQHBqLmdvYi5wZSJ9.-nX87AiyjDvfW2SeGAhWFnx0MDCiB8meK06aAAlVfJQ")*/
            .then(response => {
                setReniec(response.data);
                console.log(response.data);
                const nombre = response.data.nombres;
                const apellido = response.data.apellidoPaterno + " " + response.data.apellidoMaterno; // Ajusta la ruta de acceso correcta a la propiedad "nombres" según la respuesta del API
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    nombre: nombre,
                    apellido: apellido,
                }));
            }).catch(error => {
                console.log(error);
            })
    }

    const docIdentidadBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.docIdentidad}
            </>
        );
    }

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.nombre + ' ' + rowData.apellido}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Revisar" icon="pi pi-search" className="p-button-rounded p-button-outlined p-button mr-2" onClick={() => { VerRegistro(rowData)}}/>
                <Button title="Agregar Asistencia" icon="pi pi-check" className="p-button-rounded p-button-outlined p-button-success mr-2"
                    onClick={() => { AgregarRegistro(rowData); bloquearBotonSeg(); }} disabled={bloquearBoton} />
                <Button title="Modificar Usuario" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    }

    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveAsistente} />
        </>
    );

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Registrar Usuario" icon="pi pi-user-plus" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const onHide = () => {
        setDisplayDialog(false);
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Registro de Asistencias</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Inscritos"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="opciones" header="Opciones" body={actionBodyTemplate}></Column>
                        <Column field="docIdentidad" header="DNI" sortable body={docIdentidadBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={displayDialog} onHide={onHide}>
                        <h2>Datos de Asistencia</h2>
                        <DataTable value={asistenciaData} className="p-datatable-sm">
                            <Column field="id" header="ID"></Column>
                            <Column field="fecRegistro" header="fecha"></Column>
                        </DataTable>
                    </Dialog>
                    <Dialog visible={EntidadNewDialog} style={{ width: '700px' }} header="Datos de Usuario" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                        <div className="field col-12">
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label htmlFor="docIdentidad">Doc. de Identidad</label>
                                    <div className="p-inputgroup">
                                        <Button icon="pi pi-search" onClick={BuscarDNI} />
                                        <InputText id="docIdentidad" name="docIdentidad" value={product.docIdentidad} onChange={(e) => onInputChange(e, 'docIdentidad')} required className={classNames({ 'p-invalid': submitted && !product.docIdentidad })} />
                                    </div>
                                    {submitted && !product.docIdentidad && <small className="p-invalid">el documento de identidad es requerido.</small>}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label htmlFor="nombre">Nombres</label>
                                    <InputText id="nombre" name="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                                    {submitted && !product.nombre && <small className="p-invalid">el Nombre es requerido.</small>}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label htmlFor="apellido">Apellidos</label>
                                    <InputText id="apellido" name="apellido" value={product.apellido} onChange={(e) => onInputChange(e, 'apellido')} required className={classNames({ 'p-invalid': submitted && !product.apellido })} />
                                    {submitted && !product.apellido && <small className="p-invalid">el Apellido es requerido.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default AsisBicentenario;
