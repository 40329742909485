/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    const fecha = new Date();

    return (
        <div className="footer4 b-t spacer">
            <Container>
                <Row>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Dirección</h5>
                        <p>Jr. Pizarro N° 544</p>
                        <p>Bolívar N° 547 - Trujillo, La Libertad</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Teléfono</h5>
                        <p>Celular :  +51 967 999 416</p>
                    </Col>
                    <Col lg="3" md="6" className="m-b-30">
                        <h5 className="m-b-20">Email</h5>
                        <p><a href="#" className="link">salaunidadacademica@pj.gob.pe</a> 
                        {/* <br />Site :  <a href="#" className="link">wrapkit@wrappixel.com</a> */}
                        </p>
                    </Col>
                    <Col lg="3" md="6" >
                        <h5 className="m-b-20">Redes Sociales</h5>
                        <div className="round-social light">
                            <a href="https://web.facebook.com/CorteDeLaLibertad" className="link"><i className="fa fa-facebook"></i></a>
                            <a href="https://twitter.com/CorteLaLibertad" className="link"><i className="fa fa-twitter"></i></a>
                        </div>
                    </Col>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14">
                                <div className="m-t-10 m-b-10 copyright"></div>
                                <div className="links ml-auto m-t-10 m-b-10">
                                    {/* <a href="#" className="p-10 p-l-0">Terms of Use</a>
                                    <a href="#" className="p-10">Legal Disclaimer</a>
                                    <a href="#" className="p-10">Privacy Policy</a> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="layout-footer">
                    <img src="/logos/pjudicial.svg" alt="Logo" height="25" className="mr-2" />
                    <span className="m-t-10 m-b-10 copyright" >CSJLL {fecha.getFullYear()} © - Ing. Jheyson Gaitan</span>
                </div>
            </Container>
        </div>
    );
}
export default Footer;
