import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

import { environment } from "./util/baseUrl";

const EditorPreguntas = () => {

  let empty = {
    id: null,
    contenido: '',
    imagen: '',
    opcion1: '',
    opcion2: '',
    opcion3: '',
    opcion4: '',
    respuestaDada: '',
    respuesta: '',
    examen: {
      id: null,
      titulo: '',
      descripcion: '',
    },
  };

  const { id } = useParams();
  const baseUrl = environment.baseUrl + 'pregunta/';
  const [data, setData] = useState(null);
  const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(empty);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);


  const peticionGet = async () => {
    await axios.get(baseUrl + `examen/${id}`)
      .then(response => {
        setData(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  const peticionPost = async () => {
    delete product.id;
    product.examen.id = id;
    await axios.post(baseUrl, product)
      .then(response => {
        setData(data.concat(response.data));
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
      }).catch(error => {
        console.log(error);
        toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
      })
  }


  const peticionPut = async () => {
    await axios.put(baseUrl, product)
    .then(() => {
        var dataNueva = data;
        dataNueva.map(u => {
          if (u.id === product.id) {
            u.contenido = product.contenido;
            u.opcion1 = product.opcion1;
            u.opcion2 = product.opcion2;
            u.opcion3 = product.opcion3;
            u.opcion4 = product.opcion4;
            u.respuestaDada = product.respuestaDada;
            u.respuesta = product.respuesta;
          }
          return u;
        });
        setData(dataNueva);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
      }).catch(error => {
        console.log(error);
        toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
      })
  }
  const peticionDelete = async () => {
    console.log(product);
    await axios.delete(baseUrl + product.id)
      // .then(response => {
        .then(() => {
        const updatedProducts = data.filter(val => val.id !== product.id);
        setData(updatedProducts);
        setDeleteProductDialog(false);
        setProduct(empty);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Cuestionario eliminado correctamente', life: 3000 });
      })
      .catch(error => {
        console.log(error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el cuestionario - eliminar preguntas activas', life: 5000 });
      });
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const deleteProduct = () => {
    let _products = data.filter(val => val.id !== product.id);
    peticionDelete();
    setData(_products);
  };

  const deleteProductDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
    </>
  );


  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };


  useEffect(() => {
    peticionGet();
  }, []);

  const openNew = () => {
    setProduct(empty);
    setSubmitted(false);
    setEntidadNewDialog(true);
  }
  const hideDialogNew = () => {
    setSubmitted(false);
    setEntidadNewDialog(false);
  }
  const editProduct = (product) => {
    setProduct({ ...product });
    setEntidadNewDialog(true);
  }

  const saveProduct = () => {
    setSubmitted(true);
    if (product.contenido.trim()) {
      let _products = [...data];
      let _product = { ...product };
      if (product.id) {
        const index = findIndexById(product.id);
        _products[index] = _product;
        peticionPut();
      }
      else {
        peticionPost();
        _products.push(_product);
      }
      setEntidadNewDialog(false);
      setData(_products);
      setProduct(empty);
    }
  }

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  const onInputChange = (e, name) => {

    const val = (e.target && e.target.value) || '';
    let _product = { ...product };

    if (name === 'fecha') {
      _product[name] = val.toString();
    } else {
      _product[name] = val;
    }

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button label="Agregar Pregunta" icon="pi pi-question" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
        </div>
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
        {/* <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} /> */}
      </React.Fragment>
    )
  }

  const idBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.id}
      </>
    );
  }

  const contenidoBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.contenido}
      </>
    );
  }

  const opcion1BodyTemplate = (rowData) => {
    return (
      <>
        {rowData.opcion1}
      </>
    );
  }

  const opcion2BodyTemplate = (rowData) => {
    return (
      <>
        {rowData.opcion2}
      </>
    );
  }


  const opcion3BodyTemplate = (rowData) => {
    return (
      <>
        {rowData.opcion3}
      </>
    );
  }

  const opcion4BodyTemplate = (rowData) => {
    return (
      <>
        {rowData.opcion4}
      </>
    );
  }

  const respuestaBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.respuesta}
      </>
    );
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button title="Modificar Pregunta" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
        <Button title="Eliminar Pregunta" icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData)} />
      </div>
    );
  }

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Preguntas</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
      </span>
    </div>
  );

  const productDialogFooterNew = (
    <>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
      <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
    </>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
          <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Preguntas"
            globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
            {/* <Column field="id" header="Id" sortable body={idBodyTemplate}></Column> */}
            <Column field="contenido" header="Contenido" sortable body={contenidoBodyTemplate}></Column>
            <Column field="opcion1" header="opcion1" sortable body={opcion1BodyTemplate}></Column>
            <Column field="opcion2" header="opcion2" sortable body={opcion2BodyTemplate}></Column>
            <Column field="opcion3" header="opcion3" sortable body={opcion3BodyTemplate}></Column>
            <Column field="opcion4" header="opcion4" sortable body={opcion4BodyTemplate}></Column>
            <Column field="respuesta" header="respuesta" sortable body={respuestaBodyTemplate}></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog visible={EntidadNewDialog} style={{ width: '450px' }} header="Agregar/Edit Product" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
            <div className="p-field">
              <label htmlFor="contenido">Contenido</label>
              <InputText id="contenido" value={product.contenido} onChange={(e) => onInputChange(e, 'contenido')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.contenido })} />
              {submitted && !product.contenido && <small className="p-error">Contenido es requerido.</small>}
            </div>
            <div className="p-field">
              <label htmlFor="opcion1">Opción 1</label>
              <InputText id="opcion1" value={product.opcion1} onChange={(e) => onInputChange(e, 'opcion1')} />
            </div>
            <div className="p-field">
              <label htmlFor="opcion2">Opción 2</label>
              <InputText id="opcion2" value={product.opcion2} onChange={(e) => onInputChange(e, 'opcion2')} />
            </div>
            <div className="p-field">
              <label htmlFor="opcion3">Opción 3</label>
              <InputText id="opcion3" value={product.opcion3} onChange={(e) => onInputChange(e, 'opcion3')} />
            </div>
            <div className="p-field">
              <label htmlFor="opcion4">Opción 4</label>
              <InputText id="opcion4" value={product.opcion4} onChange={(e) => onInputChange(e, 'opcion4')} />
            </div>
            <div className="p-field">
              <label htmlFor="respuesta">Respuesta</label>
              <InputText id="respuesta" value={product.respuesta} onChange={(e) => onInputChange(e, 'respuesta')} />
            </div>
          </Dialog>
          <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {product && <span>Esta seguro de deshabilitar la pregunta <b>{product.contenido}</b>?</span>}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default EditorPreguntas;
