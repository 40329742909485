import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import { environment } from "./baseUrl";


import { HashLink as Link } from 'react-router-hash-link';

const HeaderBanner = () => {

    const baseUrl = environment.baseUrl + "auth/signin";
    const cookies = useMemo(() => new Cookies(), []);
    let navigate = useNavigate();
    const toast = useRef(null);
    const [form, setForm] = useState({
        username: '',
        password: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    }

    const iniciarSesion = async () => {
        await axios.post(baseUrl, form)
            .then(response => {
                if (response.data.nombre != null) {
                    var respuesta = response.data;
                    /* console.log(response.data);
                    console.log(respuesta.roles[0]); */
                    cookies.set('id', respuesta.id, { path: '/' });
                    cookies.set('username', respuesta.username, { path: '/' });
                    cookies.set('email', respuesta.email, { path: '/' });
                    cookies.set('nombre', respuesta.nombre, { path: '/' });
                    cookies.set('apellido', respuesta.apellido, { path: '/' });
                    cookies.set('docIdentidad', respuesta.docIdentidad, { path: '/' });
                    cookies.set('fecNacimiento', respuesta.fecNacimiento, { path: '/' });
                    cookies.set('nroTelefono', respuesta.nroTelefono, { path: '/' });
                    cookies.set('dependencia', respuesta.dependencia, { path: '/' });
                    cookies.set('estado', respuesta.estado, { path: '/' });
                    cookies.set('roles', respuesta.roles[0], { path: '/' });
                    toast.current.show({ severity: 'success', summary: 'Logeado Correctamente', detail: 'Bienvenido ' + respuesta.nombre + ' ' + respuesta.apellido, life: 3000 });
                    setTimeout(() => {
                        navigate('/');
                    }, 1500);
                }
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Usuario o Contraseña incorrectos', life: 5000 });
            })

    }

    useEffect(() => {
        if (cookies.get('id')) {
            navigate('/');
        }
        document.documentElement.style.fontSize = 12 + 'px';
    }, [cookies, navigate]);

    const styles = {
        principal: {
            width: '100%',
            height: '1180px'
        },
        headerTitle: {
            fontWeight: '800', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '60px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        headerSubTitle: {
            fontWeight: '400', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '34px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        headerDivText: {
            fontWeight: '800', // Agregar negrita al texto
            fontFamily: 'Lato, cursive',
            fontSize: '40px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        login: {
            fontWeight: '800', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '25px', // Agregar tamaño de fuente
            color: '#821520', // Agregar color de fuente
        },
        formulario: {
            border: 'thick solid #FFFFFF',
            opacity: '0.85',// opacidad del login 
            backgroundColor: '#821520', // Agregar color de fuente
        },
        component: {
            background: '#9B1B21',
            color: 'white',
        },
        texto: {
            color: '#9B1B21',
        },
        dividers: {
            fontWeight: '300',
        }
    }



    return (
        <>

            <div className="static-slider-head" style={styles.principal}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="7" md="6" className="align-self-center text-left">
                            {/* <h1 style={styles.headerTitle}>Unidad Académica</h1>
                            <p id="p3"><h2 style={styles.headerSubTitle}>Corte Superior de Justicia de la Libertad.</h2></p>
                            <Divider style={styles.dividers} />
                            <p id="p3"><h2 style={styles.headerDivText}>"Mejores personas hacen una mejor justicia"</h2></p>
                            <br /> */}
                        </Col>
                        <Col lg="1" md="6" className="align-self-center text-left">
                        </Col>

                        <Col lg="4" md="6" className="align-self-center text-left">
                            {/* <div style={styles.body} className="surface-ground px-4 py-8 md:px-4 lg:px-8 flex align-items-center justify-content-center"> */}
                            <div style={styles.formulario} className="surface-card p-4 shadow-8 border-round w-full mb-4" >
                                <br />
                                {/* <div align="center"><img src="/unidad/logos/logoua.png" alt="hyper" height={120} className="mb-0" /></div> */}
                                <div align="center"><img src="/logos/logoua.png" alt="hyper" height={120} className="mb-0" /></div>
                                {/* <h2 align="center" style={styles.login}>INICIO DE SESIÓN</h2> */}
                                <br />
                                <br />
                                <div className="mb-2">
                                    <div className="p-inputgroup">
                                        <span style={styles.component} className="p-inputgroup-addon">
                                            <i className="pi pi-user"></i>
                                        </span>
                                        <InputText placeholder="Usuario" id="username" type="text" name="username" onChange={handleChange} />
                                    </div>
                                    <br />
                                    <div className="p-inputgroup mb-4">
                                        <span style={styles.component} className="p-inputgroup-addon">
                                            <i className="pi pi-lock"></i>
                                        </span>
                                        <InputText placeholder="Contraseña" id="password" type="password" name="password" onChange={handleChange} />
                                    </div>
                                    <br />

                                    {/* <div style={styles.texto} className="flex align-items-center justify-content-between mb-6">
                                            <div className="flex align-items-center">
                                                <Checkbox id="rememberme" className="mr-2" />
                                                <label htmlFor="rememberme">Acuérdate de mí</label>
                                            </div>
                                            <label className="font-medium no-underline ml-2 text-right cursor-pointer">Olvidaste tu contraseña?</label>
                                        </div> */}

                                    <Button style={styles.component} label="Iniciar Sesion" icon="pi pi-user" className="w-full" onClick={() => iniciarSesion()} />
                                    <div style={{ textAlign: 'center' }}>
                                        <Link className="nav-link font-16" style={styles.texto} to={"/registro"}>
                                            Regístrate aquí
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Toast ref={toast} />
        </>
    );
}

export default HeaderBanner;
