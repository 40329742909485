import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { environment } from "./util/baseUrl";
import Barcode from 'react-barcode';

// import { format } from "date-fns";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const Certificado = () => {
    const cookies = new Cookies();
    const [data, setData] = useState(null);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [certificadoSeleccionado, setCertificadoSeleccionado] = useState(null);

    const baseUrl = environment.baseUrl + "certificado/user/" + cookies.get('id');

    /* const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd MMM yyyy');
    }; */

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', options);
    };


    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        peticionGet()
    }, []);

    const ponenteBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.curso.ponente}
            </>
        );
    }

    const tituloBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.curso.titulo}
            </>
        );
    }

    const codigoBodyTemplate = (rowData) => {
        return (
            <>
                <Barcode value={rowData.codigo} height={23} width={1.5} />
            </>
        );
    }

    const fechaBodyTemplate = (rowData) => {
        return (
            <>
                {formatDate(rowData.fecha)}
            </>
        );
    }

    const datosBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.datos}
                <img
                    src={"/imagenes/" + rowData.curso.codigo + ".jpg"}
                    alt={rowData.datos}
                    className="shadow-2"
                    width="50"
                />
            </>
        );
    };

    /* const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Ver certificado" icon="pi pi-folder-open" className="p-button-rounded p-button-outlined p-button-success mr-2" />
            </div>
        );
    } */

    const actionBodyTemplate = (rowData) => {
        const manejarVerCertificado = () => {
            setCertificadoSeleccionado(rowData);
        };

        return (
            <div className="actions">
                <Button
                    title="Ver certificado"
                    icon="pi pi-folder-open"
                    className="p-button-rounded p-button-outlined p-button-success mr-2"
                    onClick={manejarVerCertificado}
                />
            </div>
        );
    };

    const VistaCertificado = ({ datosCertificado, cerrarVista }) => {
        // Implementa la renderización del certificado utilizando los datos en `datosCertificado`
        // Puedes utilizar los mismos componentes y plantillas que usas en la tabla para mostrar los detalles del certificado.
        // Por ejemplo:
        return (
            <div>
                <h3>Detalles del Certificado</h3>
                <p>Código: {datosCertificado.codigo}</p>
                <p>Título: {datosCertificado.curso.titulo.toUpperCase()}</p>
                <p>Nombre: {datosCertificado.usuario.nombre.toUpperCase() + ' ' + datosCertificado.usuario.apellido.toUpperCase()}</p>
                <p>Fecha: {formatDate(datosCertificado.fecha).toUpperCase()}</p>
                <Button title="Cerrar" icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-danger mr-2" onClick={cerrarVista} />
            </div>
        );
    };

    const handleDownloadPDF = () => {
        if (certificadoSeleccionado) {
            const pdf = new jsPDF({
                orientation: 'landscape', // Cambia la orientación a apaisada (landscape)
            });

            // Agrega la imagen de fondo
            const img = new Image();
            img.src = '/certificado/certificado.jpg'; // Reemplaza con la ruta de la imagen que deseas utilizar
            img.onload = function () {
                pdf.addImage(this, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
                // Obtiene el ancho del texto
                const text = `${certificadoSeleccionado.usuario.nombre.toUpperCase()} ${certificadoSeleccionado.usuario.apellido.toUpperCase()}`;
                const textWidth = pdf.getStringUnitWidth(text) * 7.5; // Tamaño de fuente (12)

                // Calcula la posición horizontal centrada
                const pageWidth = pdf.internal.pageSize.getWidth();
                const textX = (pageWidth - textWidth) / 2;

                // Obtiene el texto para la justificación
                const textoJustificado = `Por haber asistido en calidad de PARTICIPANTE a la capacitacion ${certificadoSeleccionado.curso.titulo.toUpperCase()}, realizado de manera virtual el dia ${formatDate(certificadoSeleccionado.curso.fecha)}, con una duración de ${certificadoSeleccionado.curso.datos} académicas, organizado por la Unidad Academica de la Corte Superior de Justicia de La Libertad. En coordinación con ${certificadoSeleccionado.curso.categoria.titulo}.`;
                const textoJustificadoCurso = `Por haber asistido en calidad de PARTICIPANTE al CURSO ${certificadoSeleccionado.curso.titulo.toUpperCase()}, realizado de manera virtual desde el dia ${formatDate(certificadoSeleccionado.curso.fecha)} hasta ${formatDate(certificadoSeleccionado.curso.fechafin)}, con una duración de ${certificadoSeleccionado.curso.datos} académicas, organizado por la Unidad Academica de la Corte Superior de Justicia de La Libertad. En coordinación con ${certificadoSeleccionado.curso.categoria.titulo}.`;
                // const textoJustificado = `Por haber asistido en calidad de PARTICIPANTE a la capacitacion ${certificadoSeleccionado.curso.titulo.toUpperCase()}, realizado de manera virtual el dia ${formatDate(certificadoSeleccionado.fecha)}, organizado por la Unidad Academica de la Corte Superior de Justicia de La Libertad. En coordinación con ${certificadoSeleccionado.curso.categoria.titulo}.`;
                // Dividir el texto en líneas
                const lineas = certificadoSeleccionado.curso.esCurso
                    ? pdf.splitTextToSize(textoJustificadoCurso, pdf.internal.pageSize.getWidth() + 15)
                    : pdf.splitTextToSize(textoJustificado, pdf.internal.pageSize.getWidth() + 15);

                // Calcula la posición vertical centrada
                const pageHeight = pdf.internal.pageSize.getHeight();
                const textY = (pageHeight - (lineas.length * 0.001)) / 2; // Espaciado entre líneas de 10 puntos
                // Agrega el contenido del certificado
                pdf.setFontSize(20);
                pdf.text(text, textX, 96);
                pdf.setFontSize(10); // Establece el tamaño de fuente predeterminado para el resto del contenido

                // Agrega cada línea justificada
                lineas.forEach((linea, index) => {
                    pdf.text(linea, 50, textY + (index * 6) + 1, { align: 'justify', maxWidth: pdf.internal.pageSize.getWidth() - 30 }); // +2 mientras menos es mas arriba va
                });

                certificadoSeleccionado.curso.esCurso
                ? pdf.text(`Trujillo, ${formatDate(certificadoSeleccionado.curso.fechafin)}`, 200, 132)
                : pdf.text(`Trujillo, ${formatDate(certificadoSeleccionado.curso.fecha)}`, 200, 132);
                
                pdf.setFontSize(6);
                pdf.text(`${(certificadoSeleccionado.codigo)}`, 20, 200);
                // Guarda el PDF
                pdf.save('certificado.pdf');
            };
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Certificados</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Cuestionarios"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="codigo" header="Codigo" sortable body={codigoBodyTemplate}></Column>
                        <Column field="titulo" header="Titulo" sortable body={tituloBodyTemplate}></Column>
                        <Column field="ponente" header="Ponente" sortable body={ponenteBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column>
                        <Column field="flyer" header="Flyer" sortable body={datosBodyTemplate}></Column>
                        {/* <Column field="puntosMaximos" header="Puntos Maximos" sortable body={puntosMaximosBodyTemplate}></Column>
                        <Column field="numeroDePreguntas" header="Nro Max Preguntas" sortable body={numeroDePreguntasBodyTemplate}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    {certificadoSeleccionado && (
                        <div className="certificate-dialog">
                            <VistaCertificado
                                datosCertificado={certificadoSeleccionado}
                                cerrarVista={() => setCertificadoSeleccionado(null)}
                            />
                            <br />
                            <Button label="Descargar PDF" onClick={handleDownloadPDF} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Certificado;
