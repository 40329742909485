import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';


import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { environment } from "./util/baseUrl";

function Registro(props) {

    let empty = {
        id: null,
        username: '',
        email: '',
        password: '',
        nombre: '',
        apellido: '',
        docIdentidad: '',
        fecNacimiento: null,
        nroTelefono: '',
        estado: true,
        role: ['user']
    };

    const options = [
        'Masculino', 'Femenino', 'Otros'
    ];

    const baseUrl = environment.baseUrl + "auth/signup";
    const toast = useRef(null);
    const [data, setData] = useState(null);
    const [reniec, setReniec] = useState(null);
    const [product, setProduct] = useState(empty);
    const [submitted, setSubmitted] = useState(false);
    const [gen, setGenero] = useState('');

    const [errors, setErrors] = useState({
        username: '',
        // otros campos de errores aquí
    });

    const [confirmPassword, setConfirmPassword] = useState('');

    let navigate = useNavigate();

    const currentDate = new Date();

    const peticionRENIEC = async () => {
        // await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQG91dGxvb2suY29tIn0.hfkwOQKGq46Czu7RvqI1iSwgN8UGK02UEF4S9ZqXaCQ")
        await axios.get("https://dniruc.apisperu.com/api/v1/dni/" + product.docIdentidad + "?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpnYWl0YW5yQHBqLmdvYi5wZSJ9.-nX87AiyjDvfW2SeGAhWFnx0MDCiB8meK06aAAlVfJQ")
            .then(response => {
                setReniec(response.data);
                console.log(response.data);
                const nombre = response.data.nombres;
                const apellido = response.data.apellidoPaterno + " " + response.data.apellidoMaterno; // Ajusta la ruta de acceso correcta a la propiedad "nombres" según la respuesta del API
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    nombre: nombre,
                    apellido: apellido,
                }));
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete product.id;
        product.data = null;
        if(product.genero == null){
            product.genero = gen;
        }
        await axios.post(baseUrl, product)
            .then(response => {
                setData(response.data);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 2000 });
                setTimeout(() => {
                    navigate('/index');
                }, 2000);
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (product.nombre.trim() && product.password && product.password === confirmPassword && !errors.username && !errors.confirmPassword && product.docIdentidad.trim()) {
            peticionPost();
            setProduct(empty);
        }
    }

    const BuscarDNI = () => {
        setSubmitted(true);
        if (product.docIdentidad.trim()) {
            peticionRENIEC();
            //setProduct(empty);
        }
    }


    useEffect(() => {
        document.documentElement.style.fontSize = 12 + 'px';
    }, []);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';

        // Validar que el valor solo contenga letras y números (sin espacios ni caracteres especiales)
        if (name === 'username' && !/^[a-zA-Z0-9]+$/.test(val)) {
            setErrors({ ...errors, [name]: 'Solo se permiten letras y números.' });
        } else {
            setErrors({ ...errors, [name]: '' });
        }

        if (name === 'password' || name === 'confirmPassword') {
            if (name === 'password') {
                setProduct({ ...product, [name]: val });
            } else {
                setConfirmPassword(val);
            }

            if (product.password !== val) {
                setErrors({ ...errors, confirmPassword: 'Las contraseñas no coinciden.' });
            } else {
                setErrors({ ...errors, confirmPassword: '' });
            }
        } else {
            setProduct({ ...product, [name]: val });
        }

        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const styles = {
        body: {
            backgroundImage: "url(/logos/capacitacion.png)",
            // color: 'white',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        },
        component: {
            background: '#9B1B21',
            color: 'white',
        },
        texto: {
            color: '#9B1B21',
        },
        formulario: {
            border: 'thick solid #FFFFFF',
            opacity: '1',
        }

    }

    return (
        <>
            <Toast ref={toast} />
            <div style={styles.body} className='surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden'>
                <div className="flex flex-column align-items-center justify-content-center">
                    <div
                        style={{
                            borderRadius: '56px',
                            padding: '0.3rem',
                            background: 'linear-gradient(180deg, var(--primary-color) 10%, rgba(132, 0, 13, 0) 30%)'
                        }}
                    >
                        <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                            <div className="mb-2">
                                <Button title="Volver" icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined p-button mr-2" onClick={() => { navigate(`/index`); }} />
                                <br />
                                <h5>Registro de Usuario</h5>
                                <div className="p-fluid formgrid grid">
                                    <div className="field col-12">
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <label htmlFor="docIdentidad">Doc. de Identidad</label>
                                                <div className="p-inputgroup">
                                                    <Button icon="pi pi-search" onClick={BuscarDNI} />
                                                    <InputText id="docIdentidad" name="docIdentidad" value={product.docIdentidad} onChange={(e) => onInputChange(e, 'docIdentidad')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.docIdentidad })} />
                                                </div>
                                                {submitted && !product.docIdentidad && <small className="p-invalid">el documento de identidad es requerido.</small>}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="nombre">Nombres</label>
                                                <InputText id="nombre" name="nombre" value={product.nombre} onChange={(e) => onInputChange(e, 'nombre')} required className={classNames({ 'p-invalid': submitted && !product.nombre })} />
                                                {submitted && !product.nombre && <small className="p-invalid">el Nombre es requerido.</small>}
                                            </div>
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="apellido">Apellidos</label>
                                                <InputText id="apellido" name="apellido" value={product.apellido} onChange={(e) => onInputChange(e, 'apellido')} required className={classNames({ 'p-invalid': submitted && !product.apellido })} />
                                                {submitted && !product.apellido && <small className="p-invalid">el Apellido es requerido.</small>}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="docIdentidad">Fecha de Nacimiento</label>
                                                <Calendar id="fecNacimiento" name="fecNacimiento" maxDate={currentDate} dateFormat="dd/mm/yy" showIcon showButtonBar value={product.fecNacimiento} onChange={(e) => onInputChange(e, 'fecNacimiento')} required className={classNames({ 'p-invalid': submitted && !product.fecNacimiento })} />
                                                {submitted && !product.fecNacimiento && <small className="p-invalid">la fecha de nacimiento es requerida.</small>}
                                            </div>
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="genero">Genero</label>
                                                <Dropdown placeholder="Seleccionar Género" id="genero" name="genero" options={options} value={gen} onChange={(e) => setGenero(e.value)} />
                                                {submitted && !product.genero && <small className="p-invalid">el genero es requerido.</small>}
                                            </div>
                                        </div>
                                        <div className="formgrid grid">
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="email">Correo</label>
                                                <InputText id="email" name="email" value={product.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !product.email })} />
                                                {submitted && !product.email && <small className="p-invalid">el Correo es requerido.</small>}
                                            </div>
                                            <div className="field col-6 md:col-6">
                                                <label htmlFor="nroTelefono">Telefono</label>
                                                <InputText id="nroTelefono" name="nroTelefono" value={product.nroTelefono} onChange={(e) => onInputChange(e, 'nroTelefono')} required className={classNames({ 'p-invalid': submitted && !product.nroTelefono })} />
                                                {submitted && !product.nroTelefono && <small className="p-invalid">el Telefono es requerido.</small>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="field col-1 md:col-1">
                                        <Divider layout="vertical" />
                                    </div> */}
                                    <Divider/>
                                    <div className="field col-12">
                                        <div className="formgrid grid">
                                            <div className="field col-6 md:col-12">
                                                <label htmlFor="username">Nombre de Usuario</label>
                                                <InputText id="username" name="username" value={product.username} onChange={(e) => onInputChange(e, 'username')} required className={classNames({ 'p-invalid': submitted && (!product.username || errors.username) })} />
                                                {submitted && !product.username && <small className="p-invalid">el Usuario es requerido.</small>}
                                                {errors.username && <small className="p-invalid">{errors.username}</small>}
                                            </div>
                                            <div className="field col-6 md:col-12">
                                                <label htmlFor="password">Crear una contraseña</label>
                                                <InputText id="password" name="password" type="password" value={product.password} onChange={(e) => onInputChange(e, 'password')} required className={classNames({ 'p-invalid': submitted && !product.password })} />
                                                {submitted && !product.password && <small className="p-invalid">La contraseña es requerida.</small>}
                                            </div>
                                            <div className="field col-6 md:col-12">
                                                <label htmlFor="confirmPassword">Confirmar contraseña</label>
                                                <InputText id="confirmPassword" name="confirmPassword" type="password" value={confirmPassword} onChange={(e) => onInputChange(e, 'confirmPassword')} required className={classNames({ 'p-invalid': submitted && !confirmPassword })} />
                                                {submitted && !product.password && <small className="p-invalid">La confirmacion de contraseña es requerida.</small>}
                                                {submitted && errors.confirmPassword && <small className="p-invalid">{errors.confirmPassword}</small>}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    <div className="field col-12 md:col-9">
                                    </div>
                                    <div className="field col-12 md:col-2">
                                        <div className="formgrid grid">
                                            <div className="field col-12 md:col-12">
                                                <Button label="Registrar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Registro;