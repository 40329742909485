const localhostEndpoint = "http://localhost:8080/api/";
//const productionEndpoint = "http://143.110.237.81:8080/api/";
//const productionEnlaceEndpoint = "https://apiua.jgaitanr.xyz/api/";
//const azureproductonEndpoint = "https://ua.azurewebsites.net/api/";
//const azureproductonEndpoint = "https://apiunidad.azurewebsites.net/api/";
const doendpoint = "https://api.unidadacademicacsjll.com.pe/api/";

//se cambia la base URL
const defaultEndpoint = doendpoint;

const restrictions = {
    MIN_DATE_TO_MAKE_ORDER: new Date()
}

export const environment = {
    baseUrl: defaultEndpoint,
    businessRules: restrictions
}
