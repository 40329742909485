import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { environment } from "./util/baseUrl";

export const CuestionarioActivo = () => {

    const navigate = useNavigate();
    const [data, setData] = useState(null);

    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const baseUrl = environment.baseUrl + "examen/activo";

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        peticionGet();
    }, []);


    const idBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.id}
            </>
        );
    }

    const categoriaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.categoria.titulo}
            </>
        );
    }

    const tituloBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.titulo}
            </>
        );
    }

    const descripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.descripcion}
            </>
        );
    }

    const puntosMaximosBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.puntosMaximos}
            </>
        );
    }

    const numeroDePreguntasBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.numeroDePreguntas}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button title="Modificar Categoria" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} /> */}
                <Button title="Rendir Evaluacion" icon="pi pi-file-edit" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => { navigate(`/pregunta/${rowData.id}/${rowData.curso.id}`);}}/>
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Examenes disponibles</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Cuestionarios"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="categoria" header="Categoria" sortable body={categoriaBodyTemplate}></Column>
                        <Column field="titulo" header="Titulo" sortable body={tituloBodyTemplate}></Column>
                        <Column field="descripcion" header="Descripcion" sortable body={descripcionBodyTemplate}></Column>
                        {/* <Column field="puntosMaximos" header="Puntos Maximos" sortable body={puntosMaximosBodyTemplate}></Column>
                        <Column field="numeroDePreguntas" header="Nro Max Preguntas" sortable body={numeroDePreguntasBodyTemplate}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    
                </div>
            </div>
        </div>
    );
}

export default CuestionarioActivo;
