import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Rating } from 'primereact/rating';
import { InputText } from 'primereact/inputtext';

import { environment } from "./util/baseUrl";
import { useNavigate } from 'react-router-dom';

const ListaCursos = () => {

    
    const navigate = useNavigate();
    const baseUrl = environment.baseUrl + "curso/";
    const [data, setData] = useState(null);
    const [dataViewValue, setDataViewValue] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filteredValue, setFilteredValue] = useState(null);
    const [layout, setLayout] = useState('grid');
    // const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);

    /* const sortOptions = [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
    ]; */

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setDataViewValue(response.data);
            }).catch(error => {
                console.log(error);
            })
        console.log(data);
    }

    useEffect(() => {
        peticionGet()
    }, []);

    const redirectToLink = (url) => {
        window.open(url);
      };

    const onFilter = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
        if (value.length === 0) {
            setFilteredValue(null);
        } else {
            const filtered = dataViewValue.filter((product) => {
                return product.titulo.includes(value);
            });
            setFilteredValue(filtered);
        }
    };


    const dataViewHeader = (
        <div className="flex flex-column md:flex-row md:justify-content-between gap-2">
            {/* <Dropdown value={sortKey} options={sortOptions} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange} /> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onFilter} placeholder="Buscar por titulo" />
            </span>
            <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
        </div>
    );

    const dataviewListItem = (data) => {
        return (
            <div className="col-12">
                <div className="flex flex-column md:flex-row align-items-center p-3 w-full">
                    <img src={"/imagenes/"+data.codigo+".jpg"} alt={data.titulo} className="my-4 md:my-0 w-9 md:w-10rem shadow-2 mr-5" />
                    <div className="flex-1 flex flex-column align-items-center text-center md:text-left">
                        <div className="font-bold text-2xl">{data.titulo}</div>
                        <div className="mb-2">{data.ponente}</div>
                        <Rating value="5" readOnly cancel={false} className="mb-2"></Rating>
                        <div className="flex align-items-center">
                            <i className="pi pi-tag mr-2"></i>
                            <span className="font-semibold">{data.categoria.titulo}</span>
                        </div>
                    </div>
                    <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                        {/* <span className="text-2xl font-semibold mb-2 align-self-center md:align-self-end">${data.price}</span> */}
                        <Button icon="pi pi-video" label="Ver conferencia" disabled={data.activo === false} className="mb-2 p-button-sm"></Button>
                        {/* <span className={`product-badge status-${data.inventoryStatus()}`}>{data.inventoryStatus}</span> */}
                    </div>
                </div>
            </div>
        );
    };

    const dataviewGridItem = (data) => {
        return (
            <div className="col-12 lg:col-4">
                <div className="card m-3 border-1 surface-border" >
                    <div className="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
                        <div className="flex align-items-center">
                            <i className="pi pi-tag mr-2" />
                            <span className="font-semibold">{data.categoria.titulo}</span>
                        </div>
                        <span className={`product-badge status-`}>{data.inventoryStatus}</span>
                    </div>
                    <div className="flex flex-column align-items-center text-center mb-3">
                        <img src={"/imagenes/"+data.codigo+".jpg"}  alt={data.titulo} className="w-5 shadow-2 my-3 mx-0" />
                        <div className="text-2xl font-bold">{data.titulo}</div>
                        <div className="mb-3">{data.ponente}</div>
                        <Rating value="5" readOnly cancel={false} />
                    </div>
                    <div className="flex flex-column align-items-center justify-content-between">
                        <Button label="Ver conferencia" icon="pi pi-video" disabled={data.activo === false} onClick={() => redirectToLink(data.flyer)} />
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }

        if (layout === 'list') {
            return dataviewListItem(data);
        } else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    {/* <h5>DataView</h5> */}
                    <Button title="Volver" icon="pi pi-arrow-left" className="p-button-rounded p-button-outlined p-button mr-2" onClick={() => { navigate(`/index`);}}/>
                    <br/>
                    <DataView value={filteredValue || dataViewValue} layout={layout} paginator rows={9} sortOrder={sortOrder} sortField={sortField} itemTemplate={itemTemplate} header={dataViewHeader}></DataView>
                </div>
            </div>
        </div>
    );
};

export default ListaCursos;