/* eslint-disable */
import React, { useState } from 'react';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse, Col } from 'reactstrap';


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);


    // Estilos personalizados para el NavbarBrand
    const title = {
        //position: 'absolute',
        //top: '-90px',
        //left: '-300 px',
        transform: 'translate(-32%, 5%)',
        // Ajusta los valores de top y left según sea necesario
    };

    const styles = {
        principal: {
            width: '100%',
            height: '1180px'
        },
        texto: {
            color: '#9B1B21',
            fontWeight: '600', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '18px' // Agregar tamaño de fuente
        },
    }

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */
    /*--------------------------------------------------------------------------------*/

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar ">
                        <Col lg="2" md="6" className="align-self-center text-left">
                        {/* <NavbarBrand href="/"><img style={title} src={'/unidad/logos/logoua-csjll.png'} alt="wrapkit" width='400px' /></NavbarBrand>  */}
                            <NavbarBrand href="/"><img style={title} src={'/logos/logoua-csjll.png'} alt="wrapkit" width='400px' /></NavbarBrand>
                        </Col>
                        <Col lg="8" md="6" className="align-self-center text-left">
                            <NavbarToggler onClick={toggle}><span className="ti-menu"></span></NavbarToggler>
                            <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 ml-auto" id="h6-info">
                                <Nav navbar className="ml-auto">
                                    <NavItem>
                                        <Link className="nav-link font-16" style={styles.texto} to={"/index"}>
                                            Inicio
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link font-16" style={styles.texto} to={"/cursos"}>
                                            Capacitaciones
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link font-16" style={styles.texto} to={"/registro"}>
                                            Regístrate
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link font-16" style={styles.texto} to={"/login"}>
                                            Iniciar Sesión
                                        </Link>
                                    </NavItem>
                                </Nav>
                                {/* <div className="act-buttons">
                                <Link to="/#coming" className="btn btn-gradiant font-14">Registrate</Link>
                            </div> */}
                            </Collapse>
                        </Col>

                    </Navbar>
                </Container>
            </div>
        </div>
    );

}
export default Header;
