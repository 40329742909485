import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Menu from './pages/Menu';
import Login from './pages/Login';
import Index from './pages/Index';
import Registro from './pages/Registro';
import Modal from 'react-modal';
//import './assets/scss/style.scss';


import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css";

import 'primereact/resources/primereact.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';

import Perfil from './pages/Perfil';
import Categoria from './pages/Categoria';
import Cuestionario from './pages/Cuestionario';
import Pregunta from './pages/Pregunta';
import Curso from './pages/Curso';
import CuestionarioActivo from './pages/CuestionarioActivo';
import ListaCursos from './pages/ListaCursos';
import EditorPreguntas from './pages/EditorPreguntas';
import Certificado from './pages/Certificado';
import Usuario from './pages/Usuario';
import Jurisprudencia from './pages/Jurisprudencia';
import ListaCertificados from './pages/ListaCertificados';
import NotFoundPage from './pages/NotFoundPage';
import Bicentenario from './pages/Bicentenario';
import AsisBicentenario from './pages/AsisBicentenario';

Modal.setAppElement('#root');

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Menu />} >
          <Route exact path="perfil" element={<Perfil />} />
          <Route exact path='notfound' element={<NotFoundPage />} />
          <Route exact path="categoria" element={<Categoria />} />
          <Route exact path="usuario" element={<Usuario />} />
          <Route exact path="cuestionario" element={<Cuestionario />} />
          <Route exact path="certificado" element={<Certificado />} />
          <Route exact path="cuestionarioactivo" element={<CuestionarioActivo />} />
          <Route exact path="curso" element={<Curso />} />
          <Route exact path="listacertificados/:id" element={<ListaCertificados />} />
          <Route exact path="pregunta/:id/:idcurso" element={<Pregunta />} />
          <Route exact path="editorpreguntas/:id" element={<EditorPreguntas />} />
          <Route exact path="listacursos" element={<ListaCursos />} />
          <Route exact path="bicentenario" element={<Bicentenario />} />
          <Route exact path="asisBicentenario" element={<AsisBicentenario />} />
          <Route exact path="jurisprudencia" element={<Jurisprudencia />} />
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/index" element={<Index />} />
        <Route exact path="/registro" element={<Registro />} />
        <Route exact path="/cursos" element={<ListaCursos />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
