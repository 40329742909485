import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import Cookies from 'universal-cookie';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import { environment } from "./util/baseUrl";

export const Cuestionario = () => {

    const navigate = useNavigate();
    const [categoriaId, setCategoriaId] = useState(null);
    const [cursoId, setCursoId] = useState(null);

    let empty = {
        id: null,
        titulo: '',
        descripcion: '',
        puntosMaximos: '',
        numeroDePreguntas: '',
        activo: false,
        categoria: {
            id: categoriaId,
            titulo: ''
        },
        curso: {
            id: cursoId,
            codigo: ''
        }
    };

    //const cookies = new Cookies();

    const baseUrl = environment.baseUrl + "examen/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const [options, setOptions] = useState([]);
    const [cursos, setCursos] = useState([]);

    const peticionGet = async () => {
        await axios.get(baseUrl)
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionGetCategoria = async () => {
        await axios.get(environment.baseUrl + "categoria/")
            .then(response => {
                // Obtener los datos de la respuesta y transformarlos en el formato requerido por React Select
                const data = response.data.map(item => ({
                    value: item.id,
                    label: item.titulo
                }));
                // Establecer los datos en el estado
                setOptions(data);
                console.log(data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionGetCurso = async () => {
        await axios.get(environment.baseUrl + "curso/")
            .then(response => {
                // Obtener los datos de la respuesta y transformarlos en el formato requerido por React Select
                const data = response.data.map(item => ({
                    value: item.id,
                    label: item.codigo
                }));
                // Establecer los datos en el estado
                setCursos(data);
                console.log(data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete product.id;
        product.categoria.id = categoriaId;
        product.curso.id = cursoId;
        await axios.post(baseUrl, product)
            .then(response => {
                setData(data.concat(response.data));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPut = async () => {
        console.log(product);
        product.categoria.id = categoriaId;
        product.curso.id = cursoId;
        console.log(product);
        await axios.put(baseUrl, product)
            .then(response => {
                var dataNueva = data;
                dataNueva.map(u => {
                    if (u.id === product.id) {
                        u.titulo = product.titulo;
                        u.descripcion = product.descripcion;
                        u.puntosMaximos = product.puntosMaximos;
                        u.numeroDePreguntas = product.numeroDePreguntas;
                        u.activo = product.activo;
                        u.categoria.titulo = product.categoria.titulo;
                    }
                });
                setData(dataNueva);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }
    
    const peticionDelete = async () => {
        console.log(product);
        await axios.delete(baseUrl + product.id)
            .then(response => {
                const updatedProducts = data.filter(val => val.id !== product.id);
                setData(updatedProducts);
                setDeleteProductDialog(false);
                setProduct(empty);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Cuestionario eliminado correctamente', life: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el cuestionario - eliminar preguntas activas', life: 5000 });
            });
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const deleteProduct = () => {
        let _products = data.filter(val => val.id !== product.id);
        peticionDelete();
        setData(_products);
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    useEffect(() => {
        peticionGet();
        peticionGetCategoria();
        peticionGetCurso();
    }, []);

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }
    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }
    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (product.titulo.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPut();
                setEntidadNewDialog(false);
            }
            else {
                peticionPost();
                _products.push(_product);
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    }
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }

    const onInputNumberChange = (e, name) => {
        const val = (e.target && e.target.value) || 0;
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
    }


    const handleSwitchChange = (event) => {
        setProduct({ ...product, activo: event.value });
        console.log(product);
    };

    const handleChangeCategoria = (event) => {
        setCategoriaId(event.value);
    };

    const handleChangeCurso = (event) => {
        setCursoId(event.value);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Registrar Cuestionario" icon="pi pi-user-plus" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const idBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.curso.codigo}
            </>
        );
    }

    const categoriaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.categoria.titulo}
            </>
        );
    }

    const tituloBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.titulo}
            </>
        );
    }

    const numeroDePreguntasBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.numeroDePreguntas}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Modificar Cuestionario" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button title="Agregar Preguntas" icon="pi pi-question" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => { navigate(`/editorpreguntas/${rowData.id}`); }} />
                <Button title="Eliminar Categoria" icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Configuración de Cuestionarios</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Cuestionarios"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="id" header="Codigo" sortable body={idBodyTemplate}></Column>
                        <Column field="titulo" header="Titulo" sortable body={tituloBodyTemplate}></Column>
                        <Column field="categoria" header="Categoria" sortable body={categoriaBodyTemplate}></Column>
                        {/* <Column field="descripcion" header="Descripcion" sortable body={descripcionBodyTemplate}></Column> */}
                        {/* <Column field="puntosMaximos" header="Puntos Maximos" sortable body={puntosMaximosBodyTemplate}></Column> */}
                        <Column field="numeroDePreguntas" header="Nro max de Preguntas" sortable body={numeroDePreguntasBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={EntidadNewDialog} style={{ width: '500px' }} header="Datos" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                        <div className="field">
                            <label htmlFor="curso">Curso a evaluar</label>
                            <Dropdown placeholder={product.curso.codigo} id="curso" name="curso" options={cursos} value={cursoId} onChange={handleChangeCurso} required autoFocus/>
                            {submitted && !product.curso && <small className="p-invalid">El curso es requerido para generar el certificado.</small>}
                        </div>
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputText placeholder="Título" id="titulo" name="titulo" value={product.titulo} onChange={(e) => onInputChange(e, 'titulo')} required className={classNames({ 'p-invalid': submitted && !product.titulo })} />
                            </div>
                            {submitted && !product.titulo && <small className="p-invalid">el titulo es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="documento">Descripcion del Cuestionario</label>
                            <InputTextarea placeholder="Descripcion de la categoría" id="descripcion" value={product.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                            {submitted && !product.descripcion && <small className="p-invalid">Agregar la descripcion del cuestionario.</small>}
                        </div>
                        <div className="formgrid grid">
                            {/* <div className="field col">
                                <label htmlFor="puntosMaximos">Puntos máximos</label>
                                <InputNumber placeholder="Puntos máximos" id="puntosMaximos" name="puntosMaximos" value={product.puntosMaximos} onValueChange={(e) => onInputNumberChange(e, 'puntosMaximos')} required className={classNames({ 'p-invalid': submitted && !product.puntosMaximos })} />
                                {submitted && !product.puntosMaximos && <small className="p-invalid">La cantidad de puntos maximos es requerida.</small>}
                            </div> */}
                            <div className="field col">
                                <label htmlFor="numeroDePreguntas">Número maximo de Preguntas</label>
                                <InputNumber placeholder="Número de Preguntas" id="numeroDePreguntas" name="numeroDePreguntas" value={product.numeroDePreguntas} onValueChange={(e) => onInputNumberChange(e, 'numeroDePreguntas')} required className={classNames({ 'p-invalid': submitted && !product.numeroDePreguntas })} />
                                {submitted && !product.numeroDePreguntas && <small className="p-invalid">La cantidad de preguntas es requerida.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="activo">Activado</label>
                            <br />
                            <InputSwitch id="activo" name="activo" checked={product.activo} onChange={handleSwitchChange} />
                        </div>
                        <div className="field">
                            <label htmlFor="categoria">Categoria</label>
                            <Dropdown placeholder={product.categoria.titulo} id="categoria" name="categoria" options={options} value={categoriaId} onChange={handleChangeCategoria} required  />
                            {submitted && !product.categoria && <small className="p-invalid">La categoria es requerida.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Esta seguro de deshabilitar el cuestionario <b>{product.titulo}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Cuestionario;
