/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Row, Col, Container, Card } from "reactstrap";
import { environment } from "./baseUrl";
import Modal from 'react-modal';
import { Button } from 'primereact/button';

const BlogComponent = () => {
  const [cursosRecientes, setCursosRecientes] = useState([]);
  const baseUrl = environment.baseUrl + 'curso/';
  const [modalOpenIndex, setModalOpenIndex] = useState(null);

  const openModal = (index) => {
    setModalOpenIndex(index);
  };

  const closeModal = () => {
    setModalOpenIndex(null);
  };

  const modalStyles = {
    modalContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100vw',
      padding: 0,
      margin: 0,
    },
    modalOverlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    modalImageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    modalImage: {
      maxWidth: '100%',
      maxHeight: '95%',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      //border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };

  const peticionGet = async () => {
    await axios
      .get(baseUrl + 'ultimos')
      .then((response) => {
        setCursosRecientes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <div>
      <div className="blog-home2 spacer">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" className="text-center">
              <h2 className="title">Nuestros próximos eventos</h2>
              <h6 className="subtitle">
                Revisa la programación académica, comparte la experiencia con los mejores exponentes del derecho en nuestro país.
              </h6>
            </Col>
          </Row>
          <Row className="m-t-40 justify-content-center">
            {cursosRecientes.map((curso, index) => (
              <Col lg="4" md="6" key={curso.id}>
                <Card>
                {/* <img className="card-img-top" src={"/unidad/imagenes/" + curso.codigo + ".jpg"} alt="Curso Imagen" onClick={() => openModal(index)} style={{ cursor: 'pointer' }} /> */}
                  <img className="card-img-top" src={"/imagenes/" + curso.codigo + ".jpg"} alt="Curso Imagen" onClick={() => openModal(index)} style={{ cursor: 'pointer' }} />
                  <h5 className="font-medium m-t-30"> <a className="link">{curso.titulo} </a> </h5>
                  <p className="m-t-20">Ponente: {curso.ponente}</p>
                  <a href={curso.flyer} className="linking text-themecolor m-t-10" target="_blank" rel="noopener noreferrer">
                    Ver Conferencia <i className="ti-arrow-right"></i>
                  </a>
                </Card>
                <Modal
                  isOpen={modalOpenIndex === index}
                  onRequestClose={closeModal}
                  contentLabel="Imagen Maximizada"
                >
                  <Button onClick={closeModal} style={modalStyles.closeButton} icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-danger mr-2" />
                  <div style={modalStyles.modalImageContainer}>
                    <img
                      src={"/imagenes/" + curso.codigo + ".jpg"}
                      alt={curso.codigo}
                      style={modalStyles.modalImage}
                    />
                  </div>
                </Modal>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BlogComponent;
