import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
// import classNames from 'classnames';
import Cookies from 'universal-cookie';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';

import { environment } from "./util/baseUrl";

const Pregunta = () => {

  let empty = {
    id: null,
    codigo: '',
    fecha: '',
    actvo: true,
    usuario: {
      id: null
    },
    curso: {
      id: null,
      codigo: '',
    }
  };

  const { id, idcurso } = useParams();
  const baseUrl = environment.baseUrl + `pregunta/examen/${id}`;
  const [preguntas, setPreguntas] = useState([]); // Cambio de nombre a "preguntas"
  const [respuestas, setRespuestas] = useState({}); // Estado para almacenar respuestas
  const [preguntaActual, setPreguntaActual] = useState(0);
  const cookies = new Cookies();
  const toast = useRef(null);

  const [data, setData] = useState([]);
  const [product, setProduct] = useState(empty);
  const navigate = useNavigate();


  /* const [data, setData] = useState([]);
  const [product, setProduct] = useState(empty); */

  const peticionGet = async () => {
    try {
      const response = await axios.get(baseUrl);
      setPreguntas(response.data);
      // Al cargar las preguntas, inicializamos las respuestas con un objeto vacío
      const respuestasIniciales = {};
      response.data.forEach((pregunta) => {
        respuestasIniciales[pregunta.id] = '';
      });
      setRespuestas(respuestasIniciales);
    } catch (error) {
      console.log(error);
    }
  };

  const peticionPost = async () => {
    const date = new Date();
    try {
      delete product.id;
      product.codigo = cookies.get('docIdentidad') + idcurso;
      product.fecha = date.toUTCString();
      product.curso.id = idcurso;
      product.usuario.id = cookies.get('id');
      console.log(product);
      const response = await axios.post(environment.baseUrl + `certificado/`, product);
      setData([...data, response.data]);
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Registro correcto de certificado', life: 3000 });
      setProduct(empty);
    } catch (error) {
      console.log(error);
      toast.current.show({ severity: 'error', summary: 'Datos duplicados', detail: 'El Certificado ya fue generado anteriormente', life: 5000 });
    }
  };

  const handleRespuestaChange = (preguntaId, opcionSeleccionada) => {
    setRespuestas((prevRespuestas) => ({
      ...prevRespuestas,
      [preguntaId]: opcionSeleccionada,
    }));
  };

  const handleNextQuestion = () => {
    if (preguntaActual < preguntas.length - 1) {
      setPreguntaActual((prevPreguntaActual) => prevPreguntaActual + 1);
    }
  };

  const handlePrevQuestion = () => {
    if (preguntaActual > 0) {
      setPreguntaActual((prevPreguntaActual) => prevPreguntaActual - 1);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Respuestas seleccionadas:', respuestas);
    // Validar respuestas
    let todasLasRespuestasCorrectas = true;
    preguntas.forEach((pregunta) => {
      if (pregunta.respuesta !== respuestas[pregunta.id]) {
        todasLasRespuestasCorrectas = false;
      }
    });
    // Mostrar mensaje de éxito o fallo
    const severity = todasLasRespuestasCorrectas ? 'success' : 'error';
    const summary = todasLasRespuestasCorrectas
      ? '¡Felicidades!'
      : 'Respuestas incorrectas';
    const detail = todasLasRespuestasCorrectas
      ? 'Todas las respuestas son correctas.'
      : 'Hay respuestas incorrectas. Revise sus respuestas antes de enviar.';

    toast.current.show({ severity, summary, detail, life: 5000 });
    if (todasLasRespuestasCorrectas) {
      try {
        await peticionPost(); // Llama a la función peticionPost para grabar el certificado
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        navigate('/');
      }, 4000);
    }
    // Limpia las respuestas después de mostrar el mensaje
    setRespuestas({});
    handleNextQuestion();
  };

  useEffect(() => {
    peticionGet();
  }, []);


  return (
    <div className="card">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <form onSubmit={handleSubmit}>
            {preguntas.length > 0 && preguntaActual < preguntas.length && (
              <div className="card" key={preguntas[preguntaActual].id}>
                <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                  <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                    <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
                      <i className="pi pi-arrow-right text-xl text-green-500" />
                    </div>
                    <span className="text-900 line-height-3">
                      {/* {pregunta.contenido} */}
                      {preguntas[preguntaActual].contenido}
                    </span>
                  </li>
                </ul>
                <div className="p-d-flex p-flex-column">
                  <div className="col-12 md:col-4">
                    <div className="field-checkbox">
                      <RadioButton
                        inputId={`opcion1-${preguntas[preguntaActual].id}`}
                        name={`opcion-${preguntas[preguntaActual].id}`}
                        value={preguntas[preguntaActual].opcion1}
                        onChange={(e) => handleRespuestaChange(preguntas[preguntaActual].id, e.value)}
                        checked={respuestas[preguntas[preguntaActual].id] === preguntas[preguntaActual].opcion1}
                      />
                      <label htmlFor={`opcion1-${preguntas[preguntaActual].id}`}>{preguntas[preguntaActual].opcion1}</label>
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field-checkbox">
                      <RadioButton
                        inputId={`opcion2-${preguntas[preguntaActual].id}`}
                        name={`opcion-${preguntas[preguntaActual].id}`}
                        value={preguntas[preguntaActual].opcion2}
                        onChange={(e) => handleRespuestaChange(preguntas[preguntaActual].id, e.value)}
                        checked={respuestas[preguntas[preguntaActual].id] === preguntas[preguntaActual].opcion2}
                      />
                      <label htmlFor={`opcion2-${preguntas[preguntaActual].id}`}>{preguntas[preguntaActual].opcion2}</label>
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field-checkbox">
                      <RadioButton
                        inputId={`opcion3-${preguntas[preguntaActual].id}`}
                        name={`opcion-${preguntas[preguntaActual].id}`}
                        value={preguntas[preguntaActual].opcion3}
                        onChange={(e) => handleRespuestaChange(preguntas[preguntaActual].id, e.value)}
                        checked={respuestas[preguntas[preguntaActual].id] === preguntas[preguntaActual].opcion3}
                      />
                      <label htmlFor={`opcion3-${preguntas[preguntaActual].id}`}>{preguntas[preguntaActual].opcion3}</label>
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field-checkbox">
                      <RadioButton
                        inputId={`opcion4-${preguntas[preguntaActual].id}`}
                        name={`opcion-${preguntas[preguntaActual].id}`}
                        value={preguntas[preguntaActual].opcion4}
                        onChange={(e) => handleRespuestaChange(preguntas[preguntaActual].id, e.value)}
                        checked={respuestas[preguntas[preguntaActual].id] === preguntas[preguntaActual].opcion4}
                      />
                      <label htmlFor={`opcion4-${preguntas[preguntaActual].id}`}>{preguntas[preguntaActual].opcion4}</label>
                    </div>
                  </div>
                </div>
                <div className="p-d-flex p-jc-between">
                  {preguntaActual > 0 && (
                    <Button type="button" icon="pi pi-arrow-left" title="Pregunta anterior" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={handlePrevQuestion} />
                  )}
                  {preguntaActual < preguntas.length - 1 ? (
                    <Button type="button" icon="pi pi-arrow-right" title="Siguiente pregunta" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={handleNextQuestion} />
                  ) : (
                    <Button type="submit" icon="pi pi-check" className="p-button-rounded p-button-outlined p-button-success mr-2" title="Enviar respuestas" />
                  )}
                </div>
              </div>
            )}
          </form>
        </div >
      </div >
    </div >
  );
};

export default Pregunta;