import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { environment } from "./util/baseUrl";
import { format } from "date-fns";

export const Jurisprudencia = () => {

    const cookies = new Cookies();

    let empty = {
        id: null,
        codigo: '',
        fecha: '',
        tema: '',
        sumilla: '',
        sala: '',
        enlace: ''
    };

    const options = [
        cookies.get('dependencia')
    ];


    const baseUrl = environment.baseUrl + "jurisprudencia/";
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [sala, setSala] = useState('');
    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const peticionGet = async () => {
        await axios.get(baseUrl + "sala/" + cookies.get('dependencia'))
            .then(response => {
                setData(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const peticionPost = async () => {
        delete product.id;
        product.sala = cookies.get('dependencia');
        await axios.post(baseUrl, product)
            .then(response => {
                setData(data.concat(response.data));
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Correcto', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    const peticionPut = async () => {
        product.sala = cookies.get('dependencia');
        await axios.put(baseUrl, product)
        .then(() => {
                var dataNueva = data;
                dataNueva.map(u => {
                    if (u.id === product.id) {
                        u.codigo = product.codigo;
                        u.fecha = product.fecha;
                        u.tema = product.tema;
                        u.sumilla = product.sumilla;
                        u.sala = product.sala;
                        u.enlace = product.enlace;
                    }
                    return u;
                });
                setData(dataNueva);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ingreso Modificado', life: 3000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Datos Incorrectos', detail: 'Datos Incorrectos', life: 5000 });
            })
    }

    useEffect(() => {
        peticionGet()
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };


    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    }
    const hideDialogNew = () => {
        setSubmitted(false);
        setEntidadNewDialog(false);
    }
    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    }
    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const saveProduct = () => {
        setSubmitted(true);
        if (product.codigo.trim() && product.tema.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPut();
            }
            else {
                peticionPost();
                _products.push(_product);
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    }

    const peticionDelete = async () => {
        await axios.delete(baseUrl + product.id)
            .then(response => {
                const updatedProducts = data.filter(val => val.id !== product.id);
                setData(updatedProducts);
                setDeleteProductDialog(false);
                setProduct(empty);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Curso eliminado correctamente', life: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el curso', life: 5000 });
            });
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const deleteProduct = () => {
        let _products = data.filter(val => val.id !== product.id);
        peticionDelete();
        setData(_products);
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const redirectToLink = (url) => {
        window.open(url);
    };


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        if (name === 'fecha') {
            _product[name] = formatDate(val.toString());
        } else {
            _product[name] = val;
        }
        setProduct(_product);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Registrar Expediente" icon="pi pi-user-plus" className="p-button-outlined p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Exportar" icon="pi pi-upload" className="p-button-outlined p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const codigoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.codigo}
            </>
        );
    }

    const temaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.tema}
            </>
        );
    }

    const sumillaBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sumilla}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Ver PDF" icon="pi pi-file-pdf" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => redirectToLink(rowData.enlace)} />
                <Button title="Editar Expediente" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button title="Eliminar Expediente" icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Configuración de Jurisprudencia</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Expedientes"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header}>
                        <Column field="codigo" header="Expediente" sortable body={codigoBodyTemplate}></Column>
                        {/* <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate}></Column> */}
                        <Column field="tema" header="Tema" sortable body={temaBodyTemplate}></Column>
                        <Column field="sumilla" header="Sumilla" sortable body={sumillaBodyTemplate}></Column>
                        {/* <Column field="sala" header="Sala" sortable body={salaBodyTemplate}></Column>
                        <Column field="enlace" header="Link" sortable body={enlaceBodyTemplate}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={EntidadNewDialog} style={{ width: '500px' }} header="Datos" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                        <div className="field col">
                            <InputText placeholder="Nro de Expediente" id="codigo" name="codigo" value={product.codigo} onChange={(e) => onInputChange(e, 'codigo')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.codigo })} />
                            {submitted && !product.codigo && <small className="p-invalid">el codigo es requerido.</small>}
                        </div>
                        <div className="field col">
                            <InputText placeholder="Tema" id="tema" name="tema" value={product.tema} onChange={(e) => onInputChange(e, 'tema')} required className={classNames({ 'p-invalid': submitted && !product.tema })} />
                            {submitted && !product.tema && <small className="p-invalid">el tema es requerido.</small>}
                        </div>
                        <div className="field col">
                            <InputTextarea placeholder="Sumilla" id="sumilla" name="sumilla" value={product.sumilla} onChange={(e) => onInputChange(e, 'sumilla')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !product.sumilla })} />
                            {submitted && !product.sumilla && <small className="p-invalid">la sumilla es requerida.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="fecha">Fecha de Evento</label>
                            <Calendar placeholder={product.fecha} id="fecha" name="fecha" showIcon showButtonBar value={product.fecha} onChange={(e) => onInputChange(e, 'fecha')} required className={classNames({ 'p-invalid': submitted && !product.fecha })} />
                            {submitted && !product.fecha && <small className="p-invalid">La fecha es requerida.</small>}
                        </div>
                        <div className="field col">
                            <Dropdown placeholder="Seleccionar Sala Superior" id="sala" name="sala" options={options} value={sala} onChange={(e) => setSala(e.value)} />
                            {submitted && !product.sala && <small className="p-invalid">La sala es requerida.</small>}
                        </div>
                        <div className="field col">
                            <InputText placeholder="Enlace" id="enlace" name="enlace" value={product.enlace} onChange={(e) => onInputChange(e, 'enlace')} required className={classNames({ 'p-invalid': submitted && !product.enlace })} />
                            {submitted && !product.enlace && <small className="p-invalid">el Enlace es requerido.</small>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Esta seguro de deshabilitar el Expediente <b>{product.codigo}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

export default Jurisprudencia;
