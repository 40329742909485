import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import {addLocale} from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

import { environment } from "./util/baseUrl";

import { format } from "date-fns";

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar',
});


const Curso = () => {
    const [categoriaId, setCategoriaId] = useState(null);
    const [empty, setEmpty] = useState({
        id: null,
        codigo: '',
        titulo: '',
        descripcion: '',
        ponente: '',
        hora: '',
        fecha: '',
        fechafin: '',
        esCurso: false,
        flyer: '',
        resolucion: '',
        activo: false,
        datos: '',
        categoria: {
            id: categoriaId,
            titulo: '',
        },
    });

    const navigate = useNavigate();
    const baseUrl = environment.baseUrl + 'curso';
    const [data, setData] = useState(null);
    const [EntidadNewDialog, setEntidadNewDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [product, setProduct] = useState(empty);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [options, setOptions] = useState([]);

    const peticionGet = async () => {
        await axios
            .get(baseUrl + '/')
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const peticionGetCategoria = async () => {
        await axios.get(environment.baseUrl + 'categoria/')
            .then((response) => {
                const data = response.data.map((item) => ({
                    value: item.id,
                    label: item.titulo,
                }));
                setOptions(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const peticionPost = async () => {
        delete product.id;
        product.categoria.id = categoriaId;
        await axios
            .post(baseUrl + '/', product)
            .then((response) => {
                setData(data.concat(response.data));
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Ingreso Correcto',
                    life: 3000,
                });
            })
            .catch((error) => {
                console.log(error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Datos Incorrectos',
                    detail: 'Datos Incorrectos',
                    life: 5000,
                });
            });
    };

    const peticionPut = async () => {
        product.categoria.id = categoriaId;
        console.log(product);
        await axios
            .put(baseUrl + '/', product)
            // .then((response) => {
                .then(() => {
                var dataNueva = data;
                dataNueva.map((u) => {
                    if (u.id === product.id) {
                        u.codigo = product.codigo;
                        u.titulo = product.titulo;
                        u.descripcion = product.descripcion;
                        u.ponente = product.ponente;
                        u.fecha = product.fecha;
                        u.fechafin = product.fechafin;
                        u.esCurso = product.esCurso;
                        u.resolucion = product.resolucion;
                        u.flyer = product.flyer;
                        u.activo = product.activo;
                        u.datos = product.datos;
                        u.categoria.titulo = product.categoria.titulo;
                    }
                    return u;
                });
                setData(dataNueva);
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Ingreso Modificado',
                    life: 3000,
                });
            })
            .catch((error) => {
                console.log(error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Datos Incorrectos',
                    detail: 'Datos Incorrectos',
                    life: 5000,
                });
            });
    };

    useEffect(() => {
        peticionGet();
        peticionGetCategoria();
    }, []);



    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const openNew = () => {
        setProduct(empty);
        setSubmitted(false);
        setEntidadNewDialog(true);
    };
    const hideDialogNew = () => {
        if (!product.categoria) {
            toast.current.show({
                severity: 'error',
                summary: 'Categoria',
                detail: 'Obligatorio: Selecciona una categoria',
                life: 3000,
            });
            return;
        }
        setSubmitted(false);
        setEntidadNewDialog(false);
    };
    const editProduct = (product) => {
        setProduct({ ...product });
        setEntidadNewDialog(true);
    };

    const saveProduct = () => {
        setSubmitted(true);
        if (product.titulo.trim()) {
            let _products = [...data];
            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _products[index] = _product;
                peticionPut();
            } else  if (product.titulo.trim() && product.fecha && product.codigo) {
                peticionPost();
                _products.push(_product);
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Datos Incorrectos',
                    detail: 'Datos Incorrectos',
                    life: 5000,
                });
            }
            setEntidadNewDialog(false);
            setData(_products);
            setProduct(empty);
        }
    };

    const peticionDelete = async () => {
        await axios.delete(baseUrl + '/' + product.id)
            .then(response => {
                const updatedProducts = data.filter(val => val.id !== product.id);
                setData(updatedProducts);
                setDeleteProductDialog(false);
                setProduct(empty);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Curso eliminado correctamente', life: 3000 });
            })
            .catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el curso', life: 5000 });
            });
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const deleteProduct = () => {
        let _products = data.filter(val => val.id !== product.id);
        peticionDelete();
        setData(_products);
    };

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };

        if (name === 'fecha') {
            _product[name] = val.toString();
        } else {
            _product[name] = val;
        }

        setProduct(_product);
    };

    const handleSwitchChange = (event) => {
        setProduct({ ...product, activo: event.value });
        console.log(product);
    };

    const handleesCursoChange = (event) => {
        setProduct({ ...product, esCurso: event.value });
        console.log(product);
    };

    const handleChangeCategoria = (event) => {
        setCategoriaId(event.value);
        product.categoria.id = categoriaId;
        console.log(product);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button
                        label="Registrar Curso"
                        icon="pi pi-user-plus"
                        className="p-button-outlined p-button-success mr-2"
                        onClick={openNew}
                    />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label="Exportar"
                    icon="pi pi-upload"
                    className="p-button-outlined p-button-help"
                    onClick={exportCSV}
                />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return <>{rowData.id}</>;
    };

    const codigoBodyTemplate = (rowData) => {
        return <>{rowData.codigo}</>;
    };

    const tituloBodyTemplate = (rowData) => {
        return <>{rowData.titulo}</>;
    };

    const ponenteBodyTemplate = (rowData) => {
        return <>{rowData.ponente}</>;
    };

    const fechaBodyTemplate = (rowData) => {
        return <>{formatDate(rowData.fecha)}</>; // Utilizar la función formatDate aquí
    };
    const esCursoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.esCurso ? (
                    <span>Curso</span>
                ) : (
                    <span>Ponencia</span>
                )}
            </>
        );
    };

    const datosBodyTemplate = (rowData) => {
        return (
            <>
                <img
                    src={"/imagenes/" + rowData.codigo + ".jpg"}
                    alt={rowData.codigo}
                    className="shadow-2"
                    width="50"
                />
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button title="Modificar Curso" icon="pi pi-pencil" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button title="Eliminar Curso" icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData)} />
                <Button title="Reporte de Certificados" icon="pi pi-search" className="p-button-rounded p-button-outlined p-button-success mr-2" onClick={() => { navigate(`/listacertificados/${rowData.id}`); }} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Configuración de Cursos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooterNew = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogNew} />
            <Button label="Aceptar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable ref={dt} value={data} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Ver {first} a {last} de {totalRecords} Cursos" globalFilter={globalFilter}
                        emptyMessage="No existen registros." header={header} >
                        <Column field="id" header="Id" sortable body={idBodyTemplate}> </Column>
                        <Column field="codigo" header="Codigo" sortable body={codigoBodyTemplate} ></Column>
                        <Column field="titulo" header="Titulo" sortable body={tituloBodyTemplate} ></Column>
                        <Column field="ponente" header="Ponente" sortable body={ponenteBodyTemplate} ></Column>
                        <Column header="Es curso" sortable body={esCursoBodyTemplate}></Column>
                        <Column field="fecha" header="Fecha" sortable body={fechaBodyTemplate} ></Column>
                        <Column header="Flyer" sortable body={datosBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    <Dialog visible={EntidadNewDialog} style={{ width: '600px' }} header="Datos del Evento" modal className="p-fluid" footer={productDialogFooterNew} onHide={hideDialogNew}>
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="activo">Activar para Curso Dieguito, si es capacitacion no</label>
                                <br />
                                <InputSwitch id="activo" name="activo" checked={product.esCurso} onChange={handleesCursoChange} />
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-6">
                                <label htmlFor="titulo">Título del evento</label>
                                <InputText placeholder="Título del evento" id="titulo" name="titulo" value={product.titulo} onChange={(e) => onInputChange(e, 'titulo')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.titulo })} />
                                {submitted && !product.titulo && <small className="p-invalid">el titulo es requerido.</small>}
                            </div>
                            <div className="field col-6 md:col-6">
                                <label htmlFor="codigo">Código del evento</label>
                                <InputText placeholder="Código del evento" id="codigo" name="codigo" value={product.codigo} onChange={(e) => onInputChange(e, 'codigo')} required className={classNames({ 'p-invalid': submitted && !product.codigo })} />
                                {submitted && !product.codigo && <small className="p-invalid">El codigo  es requerido.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="descripcion">Descripcion del Curso</label>
                                <InputTextarea placeholder="Descripcion del Curso" id="descripcion" value={product.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                                {submitted && !product.descripcion && <small className="p-invalid">Agregar la descripcion del cuestinario.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label htmlFor="ponente">Nombre del Ponente</label>
                                <InputText placeholder="Nombre del Ponente" id="ponente" name="ponente" value={product.ponente} onChange={(e) => onInputChange(e, 'ponente')} required className={classNames({ 'p-invalid': submitted && !product.ponente })} />
                                {submitted && !product.ponente && <small className="p-invalid">el ponente es requerido.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-6">
                                <label htmlFor="resolucion">Nro de Resolucion</label>
                                <InputText placeholder="Nro de Resolucion" id="resolucion" name="resolucion" value={product.resolucion} onChange={(e) => onInputChange(e, 'resolucion')} required className={classNames({ 'p-invalid': submitted && !product.resolucion })} />
                                {submitted && !product.resolucion && <small className="p-invalid">la resolucion es requerida.</small>}
                            </div>
                            <div className="field col-6 md:col-6">
                                <label htmlFor="datos">Horas academicas</label>
                                <InputText placeholder="Horas academicas" id="datos" name="datos" value={product.datos} onChange={(e) => onInputChange(e, 'datos')} required className={classNames({ 'p-invalid': submitted && !product.datos })} />
                                {submitted && !product.datos && <small className="p-invalid">La hora es requerida.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-6 md:col-6">
                                <label htmlFor="fecha">Fecha de Evento</label>
                                <Calendar placeholder={product.fecha} id="fecha" name="fecha" dateFormat="dd/mm/yy" showIcon showButtonBar value={product.fecha} onChange={(e) => onInputChange(e, 'fecha')} required className={classNames({ 'p-invalid': submitted && !product.fecha })} />
                                {submitted && !product.fecha && <small className="p-invalid">La fecha es requerida.</small>}
                            </div>
                            <div className="field col-6 md:col-6">
                                <label htmlFor="fechafin">Fin del Evento (Opcional)</label>
                                <Calendar placeholder={product.fechafin} id="fecha" name="fechafin" dateFormat="dd/mm/yy" showIcon locale='es' showButtonBar value={product.fechafin} onChange={(e) => onInputChange(e, 'fechafin')} className={classNames({ 'p-invalid': submitted && !product.fechafin })} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="categoria">Categoria</label>
                            <Dropdown placeholder={product.categoria.titulo} id="categoria" name="categoria" options={options} value={categoriaId} onChange={handleChangeCategoria} />
                            {submitted && !product.categoria && <small className="p-invalid">La categoria es requerida.</small>}
                        </div>
                        <div className="field">
                            <div className="p-inputgroup">
                                <InputText placeholder="Enlace" id="flyer" name="flyer" value={product.flyer} onChange={(e) => onInputChange(e, 'flyer')} required className={classNames({ 'p-invalid': submitted && !product.flyer })} />
                            </div>
                            {submitted && !product.flyer && <small className="p-invalid">el Enlace es requerido.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="activo">Activado</label>
                            <br />
                            <InputSwitch id="activo" name="activo" checked={product.activo} onChange={handleSwitchChange} />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Esta seguro de deshabilitar el Curso <b>{product.titulo}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Curso;
