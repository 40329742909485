import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Divider } from 'primereact/divider';

const ListEvents = () => {

    const styles = {
        principal: {
            width: '100%',
            height: '600px'
        },
        headerTitle: {
            fontWeight: '800', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '50px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        headerSubTitle: {
            fontWeight: '100', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '65px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        headerDivText: {
            fontWeight: '200', // Agregar negrita al texto
            fontFamily: 'Coveltica, sans-serif',
            fontSize: '18px', // Agregar tamaño de fuente
            color: 'white', // Agregar color de fuente
        },
        dividers: {
            fontWeight: '800',
        }
    }
    return (
        <div className="coming-soon" id="coming" style={styles.principal}>
            <Container className="py-5 mt-5">
                <Row>
                    <Col md="6">
                        <div className="d-flex align-items-center">
                            <div>
                                <br/>
                                <h2 style={styles.headerTitle} >Visualiza nuestros</h2>
                                <h2 style={styles.headerTitle} >eventos recientes</h2>
                                <Divider style={styles.dividers} />
                                <h6 style={styles.headerDivText}>Aquí podrás revisar las video grabaciones de nuestros últimos eventos académicos. </h6>
                                <a href="/cursos" className="btn btn-outline-light m-r-20 btn-md m-t-30 font-14">Click aquí</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ListEvents;
