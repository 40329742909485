import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';
import { InputText } from 'primereact/inputtext';

import { environment } from "./util/baseUrl";
import { useNavigate } from 'react-router-dom';
import { AsistenteBicentenarioEntity } from './Entity/AsistenteBicentenarioEntity';
import { Toast } from 'primereact/toast';

const Bicentenario = () => {

    let empty = AsistenteBicentenarioEntity;
    const cookies = new Cookies();
    const baseUrl = environment.baseUrl + "bicentenario/";
    const [bloquearBoton, setBloquearBoton] = useState(false);
    const [product, setProduct] = useState(empty);
    const toast = useRef(null);

    const peticionPost = async () => {
        delete product.id;
        await axios.post(baseUrl, product)
            .then(() => {
                toast.current.show({ severity: 'success', summary: 'Registro Correcto', detail: 'Sigue las instrucciones del encargado de la Unidad Academica', life: 5000 });
            }).catch(error => {
                console.log(error);
                toast.current.show({ severity: 'error', summary: 'Error, Usuario registrado  ', detail: 'Sigue las instrucciones del encargado de la Unidad Academica', life: 5000 });
            })
    }

    const bloquearBotonSeg = () => {
        setBloquearBoton(true); // Deshabilitar el botón
        setTimeout(() => {
            setBloquearBoton(false); // Habilitar el botón después de 15 segundos
            setProduct(empty); // Limpiar las cajas de texto
        }, 3000);
    };

    const saveProduct = async () => {
        product.docIdentidad = cookies.get('docIdentidad');
        product.nombre = cookies.get('nombre');
        product.apellido = cookies.get('apellido');
        product.nroTelefono = cookies.get('nroTelefono');
        product.email = cookies.get('email');
        console.log(product);
        if (product.docIdentidad.trim() && product.nombre && product.apellido) {
            peticionPost();
        } else {
            toast.current.show({ severity: 'error', summary: 'Datos Incompletos', detail: 'Datos Incompletos', life: 5000 });
        }
    }

    const styles = {
        component: {
            background: '#9B1B21',
            color: 'white',
        },
        texto: {
            color: '#9B1B21',
        },
        formulario: {
            border: 'thick solid #FFFFFF',
            opacity: '1',
        }

    }


    return (
        <div className="grid list-demo">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card">
                    <div className="surface-0 text-700 text-center">
                        <label style={styles.texto} className="text-blue-600 font-bold mb-3"><i style={styles.texto} className="pi pi-book"></i>&nbsp;Unidad Academica</label>
                        <div className="text-900 font-bold text-5xl mb-3">Corte Superior de Justicia de La Libertad</div>
                        <div align="center"><img src="/bicentenario/bicentenario.jpg" alt="hyper" height={310} className="mb-2"  /></div>
                        <div className="text-700 text-2xl mb-5">Del 22 al 26 de Abril.</div>
                        <Button  style={styles.component} label="Regístrate Aquí" icon="pi pi-pencil" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                            onClick={() => { saveProduct();  bloquearBotonSeg();  }} disabled={bloquearBoton}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bicentenario;