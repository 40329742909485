import React, { useEffect } from "react";
import PropTypes from "prop-types";
import '../assets/scss/style.scss';
import Header from './util/Header';
import HeaderBanner from "./util/HeaderBanner";
import BlogComponent from "./util/BlogComponent";
import Footer from "./util/Footer";
import ListEvents from "./util/ListEvents";


const Components = () => {

    useEffect(() => {
        document.documentElement.style.fontSize = 14 + 'px';
    }, []);

    return (
        <div id="main-wrapper">
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner />
                    <BlogComponent />
                    <ListEvents />
                </div>
            </div>
            <Footer />
        </div>
    );
}

Components.propTypes = {
    classes: PropTypes.object
};

export default Components;
